import { put } from 'redux-saga/effects';
import { noop, uniqueId } from 'lodash';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';
import { createUIErrorMessage } from 'modules/errors';
import { takeRequest } from 'services/sagas/takeRequest';

import { fetchContracts as actions, fetchContractsTypes } from '../actions';
import type { Contract } from '../../types';

type ResponseData = { [key: string]: any }[];

const transformData = (data: ResponseData) =>
    data.map(doc => ({
        id: uniqueId('contracts'),
        ...doc,
    })) as Contract[];

function* fetchContracts(action) {
    const { elevatorId } = action?.payload;
    try {
        const { data } = yield* authApi.get<ResponseData>(config.api.documentContracts);
        const t = transformData(data);
        if (elevatorId) {
            const filtedData = t.filter(tag => {
                return tag.elevators.some(item => {
                    return item.elevatorId === elevatorId;
                });
            });
            yield put(actions.fetchContractsSuccess(filtedData));
            return;
        }
        yield put(actions.fetchContractsSuccess(transformData(data)));
    } catch (error) {
        log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchContractsFailure(uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchContracts',
        },
        {
            pattern: fetchContractsTypes.FETCH_CONTRACTS_REQUEST,
            handler: fetchContracts,
        },
        { pattern: fetchContractsTypes.FETCH_CONTRACTS_CANCEL, handler: noop },
    );
}
